.searchContainer {
  background-color: white;
  width: 100%;
}
.searchBody {
  height: 112px;
  width: 1200px;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
}

.cartBtn {
  width: 140px;
  height: 40px;
}
.searchBtn {
  width: 105px;
  margin-left: -3px;
  height: 43px;
}
.hotArea {
  margin-top: 10px;
  width: 474px;
  height: 18px;
  overflow: hidden;
}
.queryInp {
  margin-left: 29px;
  width: 392px;
  box-sizing: border-box;
  border: 2px solid #183b8c;
  height: 43px;
}
.districtSelector {
  display: flex;
  // justify-content: center;
  width: 80px;
  line-height: 24px;
  position: relative;
  border: 2px solid #eee;
  background: inherit;
  cursor: pointer;
  z-index: 4;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 10px;
}
.districtSelector:after {
  content: '';
  position: absolute;
  top: 8px;
  right: 12px;
  width: 8px;
  height: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  transform: rotate(45deg);
  transition: all 0.3s;
}
.districtSelector:hover::after {
  transform: rotate(225deg);
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.logo {
  height: 60px;
  width: 60px;

  cursor: pointer;
}
.logoTitle {
  font-size: 35px;
  font-weight: 600;
  margin-left: 10px;
}
.searchRow {
  display: flex;
  align-items: center;
}
.cart {
  margin-left: 40px;
  color: #183b8c;
}
.tLine {
  width: 100%;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  // background-image: linear-gradient(180deg,#fff,#f6f6f6);
  background: #f4f4f4;
  position: relative;
}
.naviContainer {
  width: 1200px;
  height: 36px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.homeTitle {
  font-size: 15px;
  font-weight: 400;
  color: #333;
  cursor: pointer;
}
.homeTitle:hover {
  color: #183b8c;
}
.homeTitleSelect {
  font-size: 15px;
  font-weight: 600;
  color: #183b8c;
  cursor: pointer;
}
.shopContainer {
  height: 44px;
  margin-left: 47px;
}

