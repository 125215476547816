
.searchContainer {
  background-color: white;
  width: 100%;
  border-bottom:2px solid #183B8C;
}
.searchBody {
  height: 112px;
  width: 1200px;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
}
.jsTitle{
  font-size: 22px;
  margin-left: 10px;
}
.cartBtn {
  width: 140px;
  height: 40px;
}
.searchBtn {
  width: 105px;
  // border: 2px solid #183B8C;
  height: 43px;
  margin-left: -3px;
}
.hotArea {
  margin-top: 10px;
  width: 474px;
  height: 18px;
  overflow: hidden;
}
.queryInp {
  margin-left: 29px;
  width: 392px;
  box-sizing: border-box;
  border: 2px solid #183B8C;
  height: 43px;
}
.districtSelector {
  display: flex;
  // justify-content: center;
  width: 80px;
  line-height: 24px;
  position: relative;
  border: 2px solid #eee;
  background: inherit;
  cursor: pointer;
  z-index: 4;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 10px;
}
.districtSelector:after {
  content: '';
  position: absolute;
  top: 8px;
  right: 12px;
  width: 8px;
  height: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  transform: rotate(45deg);
  transition: all 0.3s;
}
.districtSelector:hover::after {
  transform: rotate(225deg);
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.logo {
  height: 60px;
  width: 60px;

  cursor: pointer;
}
.logoTitle {
  font-size: 35px;
  font-weight: 600;
  margin-left: 10px;
}
.searchRow {
  display: flex;
  align-items: center;
}

.tLine {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
}
.headerTolal {
  color: #f57206;
  font-size: 12px;
  font-weight: 600;
}
.sendTo {
  color: #666666;
  font-size: 12px;
}
.select{
  margin-left: 10px;

}
.searchHistory{
  width: 380px;
  position: absolute;
  left: 0px;
  min-height: 47px;
    max-height: 187px;
    overflow: hidden;
    background: #fff;
    border: 1px solid #e2e2e2;
    padding: 20px 18px 20px 20px;
    z-index: 999;
}
.historyTitle{
  width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
}
.historyTitleLeft{

}
.historyTitleRight{
cursor: pointer;
}
.historyContent{
  width: 100%;
  max-height: 135px;
  overflow: hidden;
}
.historyItem{
    height: 26px;
    line-height: 26px;
    width: auto;
    padding-left: 8px;
    padding-right: 8px;
    background: #f2f2f2;
    color: #333;
    text-align: center;
    margin-right: 8px;
    margin-top: 15px;
    float: left;
    cursor: pointer;
    max-width: 340px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
