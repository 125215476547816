.shortCartView {
  position: absolute;
  // height:auto;
  width: 308px;
  border:1px solid #ccc;
  background-color: white;
  font-size: 10px;
  z-index: 2;
}
.cartLeft{
  display: flex;
}
.shortCartHd {
  padding-left: 10px;
  height: 25px;
  line-height: 25px;
  background-color: #f5f5f5;
}

.shortCartList {
  // height: 200px;
  max-height: 200px;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}
.cartItem {
  padding: 8px 10px;
  border-top: 1px dotted #ccc;
  display: flex;
  justify-content: space-between;
}
.cartItemImage {
  // float: left;
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  margin-right: 10px;
}
.cartGoodsName {
  width: 150px;
  height: 60px;
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  word-break:break-all;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:3;
}
.cartInfo {
  text-align: right;
  width: 85px;
}
.cartGoodsPrice {
  max-width: 85px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}
.cartGoodsDelete {
  max-width: 85px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.shortCartFt{
  padding: 8px;
 background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shortCartFtInfo{

    line-height: 29px;
}
.shortCartFtBtn{
  color: #fff;
  text-align: center;
  font-weight: 700;
  line-height: 29px;
  height: 29px;
  padding: 0 10px;
  background: #e4393c;
  border-radius: 2px;
  cursor: pointer;
}

