.base {
  width: 1600px;
  background-color: #f1f1f1;
  margin-bottom: 60px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .wrapper {
    overflow: hidden;
    background-color: white;
  }
  .image {
    width: 100%;
    height: 100%;
  }
}

