.menuContainer {
  height: 40px;
  width: 100%;
  background-color: white;
  border-bottom: 2px solid #183b8c;
}
.menuBody {
  height: 40px;
  width: 1200px;
  margin: auto;

  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}
.menuBtns {
  margin-left: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.menuItem {
  margin-left: 25px;
  margin-right: 25px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.menuItem:active {
  color: #183b8c;
}
.active {
  color: #183b8c;
}
.classBtn {
  width: 200px;
  height: 40px;
  background-color: #183b8c;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.classTitle {
  margin-left: 10px;
  color: white;
  font-size: 18px;
}
.allCategory {
  width: 200px;
  height: 696px;
  background-color: #fff;
  position: absolute;
  top: 40px;
  z-index: 99;
}

.itemList {
  cursor: pointer;
  color: #fff;
  padding: 4px 0 0 34px;
  height: 20%;
}
.itemList:hover {
  margin-top: 0px; /*和hover的margin-top有对比，原无30,现在0，相当于上移了,30px*/
  box-shadow: 0 5px 10px -5px #c9cdd4; /*盒子阴影*/
  transition: all 0.3s; /*持续时间*/
}
.itemContainer {
  position: relative;
  width: 100%;
  height: 120px;
}
.itemTitle {
  line-height: 25px;
  padding-top: 15px;
  font-size: 15px;
  color: #333;
  margin-bottom: 4px;
  font-weight: 600;
  position: relative;
}
.leftIcon {
  width: 18px;
  height: 18px;
  position: absolute;
  left: -25px;
  top: 20px;
  z-index: 999;
}

.categoryText {
  float: left;
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: grey;
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 15px;
}

.itemClassList {
  position: absolute;
  top: 40px;
  left: 200px;
  z-index: 99;
  width: 1000px;
  height: 696px;
  overflow-y: auto;
  background: #fff;
  padding: 10px 30px 25px;
  box-shadow: 10px 2px 10px 0 rgb(0 0 0 / 10%);
}
.itemClassItem {
  flex-direction: column;
  display: flex;
  width: 100%;
  padding: 7px 0;
  border-bottom: 1px solid #eee;
  background-color: #fff;
  font-size: 12px;
  color: #666;
  overflow: hidden;
}
.itemClassItemTitle {
  margin: 3px 0;
  width: 125px;
  font-size: 12px;
  color: #333;
}
.itemClassItemText {
  width: calc(100% - 125px);
  font-size: 12px;
  margin-left: -10px;
}
.itemClassItemA {
  display: inline-block;
  padding: 0 10px;
  margin: 3px 0 !important;
  color: #888;
}
.cateMore{
  color: #333;
  font-size: 14px;
  font-weight: bold;
}
