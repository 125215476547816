.searchContainer {
  background-color: white;
  width: 100%;
}
.searchBody {
  height: 112px;
  width: 1200px;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  padding-left: 32px;
}

.searchInp {
}
.searchBefore {
  background-color: white;
  border-right: #dadada88 solid 1px;
}
.cartBtn {
  width: 140px;
  height: 43px;
}
.searchBtn {
  width: 105px;
  height: 43px;
  margin-left: -3px;
}
.hotArea {
  margin-top: 10px;
  width: 474px;
  height: 18px;
  overflow: hidden;
}
.queryInp {
  width: 450px;
  box-sizing: border-box;
  border: 2px solid #183b8c;

  height: 43px;
  background-color: white;
}
.districtSelector {
  display: flex;
  // justify-content: center;
  width: 80px;
  line-height: 24px;
  position: relative;
  border: 2px solid #eee;
  background: inherit;
  cursor: pointer;
  z-index: 3;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 10px;
}
.cityName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 10px;
  width: 50px;
}
.districtSelector:after {
  content: '';
  position: absolute;
  top: 8px;
  right: 12px;
  width: 8px;
  height: 8px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  transform: rotate(45deg);
  transition: all 0.3s;
}
.districtSelector:hover::after {
  transform: rotate(225deg);
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.logo {
  height: 60px;
  width: 60px;

  cursor: pointer;
}
.logoTitle {
  font-size: 28px;
  font-weight: 600;
  margin-left: 10px;
}
.searchRow {
  display: flex;
  align-items: center;
  :global {
    .ant-input-group-addon {
      border: none;
    }
  }
}
.cart {
  margin-left: 40px;
}
.searchHistory {
  width: 380px;
  position: absolute;
  left: 70px;
  min-height: 47px;
  max-height: 187px;
  overflow: hidden;
  background: #fff;
  border: 1px solid #e2e2e2;
  padding: 20px 18px 20px 20px;
  z-index: 999;
}
.historyTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
}
.historyTitleLeft {
}
.historyTitleRight {
  cursor: pointer;
}
.historyContent {
  width: 100%;
  max-height: 135px;
  overflow: hidden;
}
.historyItem {
  height: 26px;
  line-height: 26px;
  width: auto;
  padding-left: 8px;
  padding-right: 8px;
  background: #f2f2f2;
  color: #333;
  text-align: center;
  margin-right: 8px;
  margin-top: 15px;
  float: left;
  cursor: pointer;
  max-width: 340px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shortCartView {
  position: absolute;
  // height:auto;
  width: 308px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 10px;
  z-index: 2;
}
.cartLeft {
  display: flex;
}
.shortCartHd {
  padding-left: 10px;
  height: 25px;
  line-height: 25px;
  background-color: #f5f5f5;
}

.shortCartList {
  // height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
.cartItem {
  padding: 8px 10px;
  border-top: 1px dotted #ccc;
  display: flex;
  justify-content: space-between;
}
.cartItemImage {
  // float: left;
  width: 50px;
  height: 50px;
  border: 1px solid #ddd;
  margin-right: 10px;
}
.cartGoodsName {
  width: 160px;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.cartInfo {
  text-align: right;
  width: 85px;
}
.cartGoodsPrice {
  max-width: 85px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}
.cartGoodsDelete {
  max-width: 85px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}
.shortCartFt {
  padding: 8px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shortCartFtInfo {
  line-height: 29px;
}
.shortCartFtBtn {
  color: #fff;
  text-align: center;
  font-weight: 700;
  line-height: 29px;
  height: 29px;
  padding: 0 10px;
  background: #e4393c;
  border-radius: 2px;
  cursor: pointer;
}
.areaView {
  position: absolute;
  top: 2px;
  width: 637px;
  padding-bottom: 25px;
  background: #fff;
  border: 2px solid #eee;
  z-index: 3;
}
.currentCityContainer {
  display: flex;
  padding: 25px;
}
.cityTitle {
  line-height: 30px;
  color: #999;
  font-size: 12px;
  margin-right: 20px;
}
.currentCity {
  font-size: 12px;
  line-height: 30px;
  color: #333;
  cursor: pointer;
}
.tabCity {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
}
.tabCard {
  flex: 1;
  line-height: 40px;
  text-align: center;
  border-bottom: 2px solid #eee;
  cursor: pointer;
}
.activeTab {
  border-top: 2px solid #eee;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom-color: #fff;
  margin: -2px -2px 0;
}
.selector {
  display: flex;
  width: 637px;
  padding: 5px 20px;
  line-height: 28px;
}
.letter {
  flex-shrink: 0;
  width: 15px;
  text-align: center;
  color: #999;
  margin-right: 18px;
}
.items {
  display: flex;
  width: 590px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.item {
  color: #333;
  padding-right: 10px;
  font-size: 12px;
  cursor: pointer;
}
.item:hover {
  color: #183b8c;
}

.goodsAttr {
  color: #888;
  max-height: 60px;
  margin-left: 19px;
  line-height: 20px;
  overflow-y: auto;
  display: flex;
  // flex-direction: column;
  // // align-items: center;
  // justify-content: center;
}

