.refund-goods-container {
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;

  .order-refund-goods-header {
    height: 60px;
    background: #f5f5f6;
  }
  .col {
    font-size: 16px;
    font-weight: bold;
    color: #080629;
    // display: flex;
    // align-items: center;
    // justify-items: center;

    text-align: center;
  }
  .goods-value {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding-top: 15px;
    padding-bottom: 15px;
    .input {
      text-align: center;
      height: 40px;
      width: 150px;
    }
    .liji{
      margin-right: 10px;
    }
  }


  .bottom-count {
    border-top: 1px solid #f5f5f6;
    padding-left: 56px;
    padding-right: 26px;
    height: 99px;
    background-color: white;
    color: #080629;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    background-color: white;
    justify-content: space-between;
    .total-left {
      display: flex;
      align-items: center;
      .offer-total {
        margin-left: 29px;
        width: 130px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f2f5f5;
        border-radius: 8px;
      }
      .offer-price {
        font-weight: 700;
        margin-left: 29px;
      }
    }
    .total-right {
      display: flex;
      align-items: center;
    }
  }
}

