.footerContainer{
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #292B32;
}
.footer {
  width: 100%;
}
.footerImage {
  background-color: #2a2b32;
}
.footerContent {
  display: flex;
  // justify-content: flex-start;
  background-color: #2a2b32;
  justify-content: center;
  width: 1200px;
  margin: 0 auto;
}
.footerList {
  display: flex;
}
.footerListitem {
  padding: 0 125px 0 0;
}
.footerItemTitle {
  font-size: 15px;
  color: snow;
  letter-spacing: 0;
  margin-top: 12px;
  margin-bottom: 13px;
}
.footerTextItem {
  font-size: 12px;
  color: #aaa;
  letter-spacing: 0;
  font-weight: 400;
  line-height: 35px;
  cursor: pointer;
}
.footerQrCodeImage {
  display: flex;
  justify-content: center;
  flex: 1;
}
.footerQrCodeImageItem {
  display: flex;
  flex-direction: column;
  margin-right: 40px;
}
.footerQrCode {
  width: 120px;
  height: 120px;
}
.qrText {
  font-size: 12px;
  color: white;
  letter-spacing: 0;
  margin-bottom: 17px;
}

.beianContainer {
  width: 100%;
  height: 100px;
  padding: 26px 0;
  color: #fff;
  font-size: 10px;
  text-align: center;
  background-color: #1c1d20;
  width: 100%;
  .icp{
    margin-left: 5px;
    cursor: pointer;
    color: #fff;
  }
}

