
.searchContainer {
  background-color: white;
  width: 100%;
  // border-bottom:2px solid #183B8C;
}
.searchBody {
  height: 112px;
  width: 1200px;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
}

.tipContainer{
  width: 1200px;
  margin: auto;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 32px;
}

.logoContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.logo {
  height: 60px;
  width: 60px;

  cursor: pointer;
}
.logoTitle {
  font-size: 35px;
  font-weight: 600;
  margin-left: 10px;
}
.jsTitle{
  font-size: 22px;
  margin-left: 10px;
}
