.loginview {
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  height: 240px;
}
.smsView {
  display: flex;
}

.loginLink {
  position: absolute;
  bottom: 70px;
  left: 30px;
  font-size: 12px;
  width: 240px;
}
.rule {
  color: #183b8c;
  cursor: pointer;
}
.modalChooseType {
  margin-top: 15px;
  font-size: 10px;
  display: flex;
  justify-content: space-between;
}
.goLogin {
  color: #0068cc;
  cursor: pointer;
}

