.headerContainer {
  width: 100%;
  background-color: #31343a;
  display: flex;
  justify-content: space-between;
}
.header {
  height: 32px;
  width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerActionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.headerTitleMain {
  color: #f38003;
  font-size: 10px;
  font-weight: 700;
  line-height: 32px;
  cursor: pointer;
}
.headerTitle {
  color: white;
  font-size: 10px;
  font-weight: 700;
  cursor: pointer;
}
.userPop {
  background-color: #31343a;
  color: white;
}
.headerlogout {
  padding: 5px;
  cursor: pointer;
}
