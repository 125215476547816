.order-refund-item {
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
  .order-refund-list-header {
    background: #f5f5f6;
    height: 60px;
  }
  .col {
    font-size: 16px;
    font-weight: bold;
    color: #080629;
    text-align: center;
  }
  .text-content {
    min-height: 60px;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .col-content {
    padding-top: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #080629;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    .offline-images {
      width: 100%x;
      display: inline-grid;
      // justify-content: space-around;
      grid-template-columns: 80px 80px 80px;
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      .img {
        width: 80px;
        height: 80px;

        border-radius: 4px;
      }
    }
  }
}

.title-view {
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #080629;
  padding-left: 71px;
}
.title-view-normal {
  height: 70px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #080629;
}
.form-title-view {
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #080629;
  padding-left: 71px;
}

.order-refund-list-item {
  background-color: white;
  margin-top: 20px;

  box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.08);
  .order-refund-item-header {
    background: #f5f5f6;
    height: 60px;
    display: flex;
    padding-left: 56px;
    font-size: 16px;
    font-weight: 600;
    color: #080629;
  }
  .order-refund-item-goods {
    height: 106px;
    padding-left: 56px;
    font-size: 16px;
    font-weight: 500;
    color: #080629;

    .img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
      border-radius: 4px;
    }
    .goods {
      font-weight: 500;
      color: #080629;
      max-width: 300px;
      text-align: left;
      margin-left: 10px;

      .goods-name {
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .spec {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 10px;
        // width: 240px;
      }
    }
    .count {
      font-size: 16px;
      align-self: flex-end;
      padding-bottom: 5px;
      margin-left: 20px;
    }
    .more {
      font-weight: 500;
      font-size: 16px;
      width: 40px;
      height: 40px;
      background-color: #f2f5f5;
    }
  }
  .col1 {
    width: 184px;
  }
  .col2 {
    width: 213px;
  }
  .col3 {
    // width: 298px;
    flex: 1;
  }
  .col4 {
    width: 168px;
  }
  .col5 {
    width: 183px;
  }
  .col6 {
    width: 186px;
  }
  .col7 {
    width: 128px;
  }

  .order-refund-item-bottom {
    border-top: 1px solid #f5f5f6;
    height: 99px;
    padding-left: 56px;
    color: #080629;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding-right: 30px;
    .bottom-left {
      display: flex;
      align-items: center;
      flex-direction: row;
      .price-title {
        width: 130px;
        height: 40px;
        margin-left: 20px;
        background-color: #f2f5f5;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: 8px;
      }
      .price {
        margin-left: 10px;
      }
    }
    .action-btn {
      width: 162px;
      height: 54px;
      font-size: 18px;
      font-weight: 600;
      border-radius: 4px;
    }
  }

}

