.twoLineBreak {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.order_refund_container {
  width: 100%;
  .top-header {
    padding-top: 20px;
    background: #fff;
    padding-bottom: 20px;
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.08);
  }
  .info-view {
    margin-top: 30px;

    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.08);
    background: #fff;
  }
  .action-view {
    background-color: #fff;
    margin-top: 30px;
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.08);
    padding-bottom: 30px;
  }
  .bottom-action-view {
    background-color: #fff;
    box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.08);
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: flex-end;
    .action_btn {
      margin-right: 20px;
      width: 160px;
      height: 52px;
      font-size: 18px;
      font-weight: 700;
    }
  }
  .offline-account-title{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // padding-right: 7px;
  }
  .form-view {
    background-color: #fff;
    padding-left: 71px;
    font-size: 16px;
    font-weight: 700;
    color: #080629;
    margin-top: 20px;
    .form-item {
      display: flex;
      // align-items: center;
      margin-bottom: 26px;
      .form-title {
        font-size: 16px;
        font-weight: 600;
        width: 100px;
        position: relative;
        // text-align: right;
      }
      .form-title::after {
        content: ':';
      }
    }
    .required:before {
      content: '* ';
      color: red;
      position: absolute;
      left: -8px;
    }
  }
  .input {
    width: 200px;
  }

  .refund-actions-container {
    background-color: white;
    padding-left: 20px;
    padding-right: 20px;
    color: #080629;
    .required:before {
      content: '* ';
      color: red;
      // position: absolute;
      // left: -8px;
    }
    .header {
      height: 60px;
      background: #f5f5f6;
    }
    .col {
      font-size: 16px;
      font-weight: 700;

      // display: flex;
      // align-items: center;
      // justify-items: center;

      text-align: center;
    }
    .col-pic {
      font-size: 16px;
      font-weight: 700;
      padding-left: 120px;
    }
    .col-attachment {
      font-size: 16px;
      font-weight: 700;
      padding-left: 140px;
    }
    .value1 {
      font-size: 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding-bottom: 10px;
      padding-top: 30px;
      padding-left: 100px;
    }
    .value {
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      padding-bottom: 10px;
      padding-top: 30px;
    }
  }


  .price {
    font-size: 16px;
    font-weight: 500;
    color: #e62e05;
  }
  .base-line {
    align-items: flex-start;
  }
}

