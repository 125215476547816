.antText() {
  font-size: 20px;
  font-weight: 600;
  color: #5e6982;
}
.antSubText() {
  font-size: 18px;
  font-weight: 600;
  color: #5e6982;
}
.workbench_container {
  height: 100vh;
  display: flex;
  width: 100%;
  overflow: scroll;

  .left {
    .workbench-title {
      height: 107px;
      display: flex;
      padding-left: 44px;
      padding-top: 40px;
      align-items: center;
      background-color: #fff;
      cursor: pointer;
      .logo {
        width: 50px;
        height: 50px;
      }
      .title {
        margin-left: 18px;
      }
    }
    .menu {
      ul {
        background-color: #fff;
      }
      .ant-menu-submenu-arrow {
        display: none;
      }
      margin-top: 70px;
      .ant-menu-vertical .ant-menu-item {
        padding-left: 40px;
      }
      .ant-menu-item-selected {
        background-color: #e5f1ff;
        // color: #5e6982;
      }
      .ant-menu-inline > .ant-menu-item {
        .antSubText();
      }
      .ant-menu-inline > .ant-menu-item > .ant-menu-title-content {
        padding-left: 14px;
      }
      .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
        .antText();
      }
    }
    width: 300px;
    z-index: 10;
    box-shadow: 0px 4px 8px 0px rgba(141, 141, 141, 0.07);
  }
  .content-container {
    background-color: #fbfbfb;
    flex: 1;
    display: flex;
    flex-direction: column;

    .navi {
      height: 107px;
      width: 100%;
      background-color: white;
      box-shadow: 0px 4px 8px 0px rgba(141, 141, 141, 0.07);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;
      .order-code {
        // width: 200px;
        height: 30px;
        background: #d8d8d8;
        border-radius: 15px;
        font-weight: 600;
        padding: 7px;
        margin-left: 10px;
      }
      .navi-title {
        height: 32px;
        font-size: 27px;
        font-weight: 600;
        color: #080629;
        line-height: 31px;
      }
      .navi-avatar {
        width: 75px;
        height: 75px;
        background-color: antiquewhite;
        border-radius: 50%;
      }
      .pop-item {
        cursor: pointer;
      }
    }
    .navi-back {
      display: flex;
      align-items: center;
      width: 80px;
      margin-left: 0px;
      margin-bottom: 10px;
      align-self: flex-start;
      cursor: pointer;
      &__text {
        color: #080629;
        font-size: 20px;
        margin-left: 5px;
        font-weight: bold;
      }
    }
    .content {
      // flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: calc(100vh - 107px);
      overflow: scroll;
      padding: 20px;
    }
  }
}

// .avatar {
//   cursor: pointer;

//   span {
//     margin-left: 10px;
//     vertical-align: middle;
//   }

// }

// :global {
//   .ant-pro-page-container {
//     margin: 0;
//   }
// }
// }

// .menu_box {
// :global {
//   .anticon {
//     margin-right: 8px;
//   }
// }

