.headerContainer {
  width: 100%;
  height: 80px;
  background-color: white;
}
.content {
  width: 1600px;
  height: 80px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content:space-between;
}
.menuBtns {
  display: flex;
  width: 600px;
  justify-content: space-between;

}
.menuItem {
  margin-left: 25px;
  margin-right: 25px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.menuItem:active {
  color: #183b8c;
}
.active {
  color: #183b8c;
}
.mineInfo{
  cursor: pointer; 
}
.popItem {
  cursor: pointer;
  width: 200px;
  height: 50px;
  font-size: 14px;
  color: #262626;
  display: flex;
  align-items: center;
  justify-content: center;

}
.popItem:hover{
  color: #183B8C;
  background-color: #F3F6FF;
}


