
.container {
  width: 1200px;
  margin: auto;
  min-height: 100vh;
  margin-bottom: 20px;
}
.fullContainer {
  width:100%;
  margin: auto;
  min-height: 100vh;
  margin-bottom: 20px;
}
.nick-name {
  color: aliceblue;
}


.containerV2 {
  // width: 1600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  margin-bottom: 20px;
}

.block {
  display: block;
}
.white {
  width: 100%;
  height: 40px;
  position: absolute;
  background-color: white;
  z-index: 1;
  border-bottom: 2px solid #183b8c;
}

.item:hover {
  color: #183b8c;
}





