.container {
  height: 100%;
  cursor: pointer;
}
.titleText{
   font-size: 16px;
    color: #333333;
    font-weight: bold;
}
.subBox{
   margin-top: 12px;
  display: flex;
  align-items: center;
  height: 13px;

}
.tuijImage{
  width:54px;
  height:13px;
}
.yirenzheng{
  width:38px;
  height:14px;
}

