@import 'antd/dist/antd.less';
@primary-color: #183b8c;


body {
  -webkit-font-smoothing: antialiased;
}

.body_container {
  padding: 20px;
  background-color: #fff;
}

.flex_r_c {
  display: flex;
  align-items: center;
}
.flex_r_c_e {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.flex_c_c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fx {
  display: flex;
}
.orang_color {
  color: #f57206;
}
div {
  display: block;

}
.textBreak {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.twoLineBreak{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.c-p{
  cursor: pointer;
}

.attrItem{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.zent-input{
  text-align: center;
}
.ant-modal-title{
  padding-right: 20px;
}

/deep/ .ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  pointer-events: auto;
  max-width: 1000px;
  display: inline-block;
}


